import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { serverUrl } from "../../App";
import { Button } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

export const ActiveVehiclesList = () => {
  const [userMasterObject, setUserMasterObject] = useState(null);
  const [searchInList, setsearchInList] = useState("");
  const [makeModelData, setMakeModelData] = useState([]);
  const [makeModelCount, setmakeModelCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 100; // Set the number of items per page
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const navigate = useNavigate();
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;

  // Calculate the range of records being displayed
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + makeModelData.length - 1,
    currentPage * itemsPerPage
  );

  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [sortBy, setSortBy] = useState("modelName");
  const [reverse, setReverse] = useState(true);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const hasToastShownRef = useRef(false);

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    // debugger;
    const fetchActiveVehiclesData = async () => {
      if (userMasterObject) {
        await fetchMakeModelData(currentPage);
      }
    };

    fetchActiveVehiclesData();
  }, [userMasterObject, currentPage, sortBy, reverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1);
      fetchMakeModelData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchInList(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchMakeModelData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    // debugger;
    if (searchInList) {
      // console.log("Fetching data due to search button click");
      setCurrentPage(1);
      fetchMakeModelData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchInList]);

  useEffect(() => {
    if (makeModelData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [makeModelData]);

  // Fetch data from the API
  const fetchMakeModelData = async (page) => {
    // Set loading state to true
    setIsLoadingListData(true);

    try {
      const response = await fetch(serverUrl + "vehicle/makemodel/active/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          searchText: searchInList || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.makeModelResultsList != null &&
            data.entity.makeModelResultsList.length > 0
          ) {
            setMakeModelData(data.entity.makeModelResultsList || []);
            setmakeModelCount(data.entity.totalCount || 0);
            // Calculate total pages based on totalCount and itemsPerPage
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            // if (isLoadedListDataWarning) {
            // console.error("No make model data found: " + data.entity);
            // toastWarning("No make model data found", 3000);
            // return;
            // }
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error fetching MakeModel data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }

    // Fetch data for the new page
    // await fetchMakeModelData(newPage);

    // Update current page after fetching data
    setCurrentPage(newPage);
  };

  // const handleSearch = () => {
  //   setCurrentPage(1); // Reset current page to 1
  //   fetchMakeModelData(1);
  // };
  // const handleSearch = () => {
  //   if (!searchInList.trim()) {
  //     toastWarning("Please enter a search keyword.", 3000);
  //     return;
  //   }

  //   setCurrentPage(1); // Reset to first page
  //   fetchMakeModelData(1);
  // };

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  //Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  const handleRowClick = (makemodel) => {
    // debugger;
    if (makemodel != null) {
      const idType = "makeModelMasterId";
      navigate(`/vehiclesTested/${makemodel.makeModelMasterId}/${idType}`, {
        state: { makeModelData: makemodel },
      });
    } else {
      toastWarning("makeModelMasterId not found", 3000);
      return;
    }
  };

  //Get All Records Once For CSV
  const exportList = async () => {
    try {
      const response = await fetch(serverUrl + "activevehicles/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          searchText: searchInList || "",
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSort = (newSortBy) => {
    // debugger;
    setReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSortBy(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Active Vehicles Found", 3000);
      setMakeModelData([]);
    }
    // Reset to initial values if no data is found
    setmakeModelCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    // debugger;
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      // toastWarning("Please enter a search keyword.", 3000);
      return;
    }
    setsearchInList(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              // value={searchInList}
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                              // onChange={(e) => setsearchInList(e.target.value)}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchInList("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>
                        <Button
                          variant="primary"
                          className="mx-1 mb-2 btns"
                          onClick={exportList}
                          disabled={makeModelData.length === 0}
                        >
                          Export
                        </Button>
                      </div>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th className="text-left">Make</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("modelName")}
                                >
                                  Model &nbsp;
                                  {sortBy === "modelName" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleSort("vehicleBodyType.bodyType")
                                  }
                                >
                                  Type &nbsp;
                                  {sortBy === "vehicleBodyType.bodyType" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th onClick={() => handleSort("testCount")}>
                                  Vehicles Tested &nbsp;
                                  {sortBy === "testCount" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isLoadingListData &&
                                makeModelData.length > 0 &&
                                makeModelData.map((makeModel, index) => (
                                  <tr
                                    className="clickable-row"
                                    onClick={() =>
                                      handleRowClick({
                                        makeModelMasterId: makeModel.id,
                                        makeName: makeModel.makeName,
                                        modelName: makeModel.modelName,
                                      })
                                    }
                                    key={makeModel.id}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    <td>{startingSerialNumber + index}</td>
                                    <td className="text-left">
                                      {makeModel.makeName}
                                    </td>
                                    <td className="text-left">
                                      {makeModel.modelName}
                                    </td>
                                    <td className="text-left">
                                      {
                                        makeModel.vehicleBodyTypeMasterObj
                                          .bodyType
                                      }
                                    </td>
                                    <td>
                                      {makeModel.vehicleTestObj
                                        ? makeModel.vehicleTestObj.vehicleCount
                                        : 0}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {!isLoadingListData && makeModelData.length === 0 && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "15px",
                              }}
                            >
                              <img src={Notfound} alt="Not Found" />
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontWeight: "bold",
                                }}
                              >
                                No Active Vehicles Found
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {!isLoadingListData && makeModelData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 ||
                                  makeModelData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {/* {rangeStart} - {rangeEnd} of {makeModelCount}{" "} */}
                                {makeModelData.length > 0 ? rangeStart : 0} -{" "}
                                {makeModelData.length > 0 ? rangeEnd : 0} of{" "}
                                {makeModelData.length > 0 ? makeModelCount : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  makeModelData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveVehiclesList;
