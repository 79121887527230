import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTrash,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { serverUrl } from "../../App";
import VehiclesTestedList from "./VehiclesTestedList";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

const DevicesList = () => {
  const [devices, setDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 100; // Set the number of items per page
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deviceGenerateCount, setDeviceGenerateCount] = useState(1);
  const [newDeviceToken, setNewDeviceToken] = useState([]);
  const [searchDeviceList, setSearchDeviceList] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selected, setSelected] = useState(false);
  const [options, setOptions] = useState([]);
  const [buId, setBuId] = useState();
  const [deviceCount, setdeviceCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [buMasterId, setBuMasterId] = useState();
  const [disableGenerate, setDisableGenerate] = useState(false);
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [invalidInputError, setInvalidInputError] = useState("");
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const [sortBy, setSortBy] = useState("updatedDate");
  const [reverse, setReverse] = useState(false);
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [isLoadedListDataWarning, setIsLoadedListDataWarning] = useState(false);
  const [unassignedDevices, setUnassignedDevices] = useState(0);
  // Calculate the range of records being displayed
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + devices.length - 1,
    currentPage * itemsPerPage
  );

  let deviceType = "";

  const { pageType } = useParams();

  if (pageType === "activeDevices") {
    deviceType = "ACTIVE";
  }
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const hasToastShownRef = useRef(false);
  const location = useLocation();
  const { selectedServiceStation } = location.state || {};

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    // debugger;
    const fetchDevicesData = async () => {
      if (userMasterObject) {
        await fetchDeviceData(currentPage);
        fetcDashboardCounts();
      }
    };

    fetchDevicesData();
  }, [userMasterObject, currentPage, sortBy, reverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1);
      fetchDeviceData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setSearchDeviceList(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchDeviceData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    // debugger;
    if (searchDeviceList) {
      // console.log("Fetching data due to search button click");
      setCurrentPage(1);
      fetchDeviceData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchDeviceList]);

  useEffect(() => {
    if (devices.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [devices]);

  const fetchDeviceData = async (page) => {
    // debugger;
    // Set loading state to true
    setIsLoadingListData(true);

    try {
      const response = await fetch(serverUrl + "device/devices/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // ouMasterId: userMasterObject.ouMasterId,
          ...(selectedServiceStation
            ? {
                buMasterId: selectedServiceStation.buMasterId,
                ouMasterId: selectedServiceStation.ouMasterId,
              }
            : { ouMasterId: userMasterObject.ouMasterId }),
          deviceStatusId: deviceType,
          searchText: searchDeviceList || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.deviceList != null &&
            data.entity.totalCount != null &&
            data.entity.deviceList.length > 0
          ) {
            setDevices(data.entity.deviceList);
            setdeviceCount(data.entity.totalCount);
            // Calculate total pages based on totalCount and itemsPerPage
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            // if (isLoadedListDataWarning) {
            // console.error("No device data found.: " + data.entity);
            // toastWarning("No device data found.", 3000);
            // return;
            // }
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error(
          "Error: Unable to fetch device data & device total count"
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  // On Table Row Click
  const handleRowClick = (device, e) => {
    // console.log(device, "Device");

    if (device) {
      const idType = "deviceTokenId";

      //If the records count is 0 then show warning
      if (device.vehicleTestObj.vehicleCount === 0) {
        e.stopPropagation();
        toastWarning("No Records Found", 3000);
        return;
      } else
        navigate(`/vehiclesTested/${device.id}/${idType}`, {
          // state: { device, pageType },
          state: { device: { ...device, pageType } },
        });
    } else {
      toastWarning("No Device Records Found", 3000);
      return;
    }
    return <VehiclesTestedList prop={device} />;
  };

  const openDeleteConfirmation = (e, device) => {
    e.stopPropagation();
    setDeviceToDelete(device);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  //Deletion Confirm Function
  const confirmDelete = async (e) => {
    toastSuccess("Successfully Deleted");
    if (deviceToDelete) {
      try {
        const response = await fetch(serverUrl + "device/delete/devicetoken", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: userMasterObject.ouMasterId,
            deviceTokenId: deviceToDelete.id,
          }),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.entity != null) {
            const updatedDevices = devices.filter(
              (device) => device.deviceTokenId !== deviceToDelete.deviceTokenId
            );
            setDevices([...updatedDevices]);
            setCurrentPage(1);
            fetchDeviceData(1);
          } else if (data.errors != null) {
            let errorDiscription = data.errors.errorDescription;

            toastWarning(errorDiscription, 3000);

            return;
          } else {
            toastWarning("Something Went Wrong", 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  //Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }

    // Fetch data for the new page
    // await fetchDeviceData(newPage);

    // Update current page after fetching data
    setCurrentPage(newPage);
  };

  // Search Functionality
  // const handleSearch = () => {
  //   debugger;
  //   setCurrentPage(1); // Reset current page to 1
  //   fetchDeviceData(1);
  // };
  // const handleSearch = () => {
  //     debugger;
  //   if (!searchDeviceList.trim()) {
  //     toastWarning("Please enter a search keyword.", 3000);
  //     return;
  //   }

  //   setCurrentPage(1); // Reset to first page
  //   fetchDeviceData(1);
  // };

  //Get All Records Once For CSV
  const exportList = async () => {
    // debugger;

    try {
      const response = await fetch(serverUrl + "devices/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          deviceStatusId: deviceType,
          searchText: searchDeviceList || "",
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Navigate to Unassigned Devices page
  //  const toUnassignedDevices = () => {
  //   navigate(`/unusedDevices`);
  // };

  const toUnassignedDevices = () => {
    const pageType = "unusedDevices";
    navigate(`/${pageType}`, {
      state: { selectedServiceStation: selectedServiceStation || null },
    });
  };

  const fetcDashboardCounts = async () => {
    // debugger;
    try {
      const response = await fetch(serverUrl + "device/registerdevices/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // ouMasterId: userMasterObject.ouMasterId
          ...(selectedServiceStation
            ? {
                buMasterId: selectedServiceStation.buMasterId,
                ouMasterId: selectedServiceStation.ouMasterId,
              }
            : { ouMasterId: userMasterObject.ouMasterId }),
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (data.entity.unassignedDevices != null) {
            setUnassignedDevices(data.entity.unassignedDevices);
          } else {
            console.error("No unassignedDevices Counts found: " + data.entity);
            toastWarning("No unassignedDevices Counts found", 3000);
            return;
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error fetching dashboard count data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSort = (newSortBy) => {
    // debugger;
    setReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSortBy(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Devices Found", 3000);
      setDevices([]);
    }
    // Reset to initial values if no data is found
    setdeviceCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    // debugger;
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      // toastWarning("Please enter a search keyword.", 3000);
      return;
    }
    setSearchDeviceList(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <ToastContainer />
          <div className="row px-0 mx-0 d-flex">
            <div className="col-md-12 mx-0 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0 mx-0">
                  <div className="container-fluid px-0 mx-0">
                    <Header />
                  </div>
                </div>
              </div>

              <div className="row px-2 mx-0 pb-2">
                <div
                  className="card-container-test mt-2 px-2"
                  style={{ maxheight: "70vh", backgroundColor: "white" }}
                >
                  <div className="my-2">
                    <div
                      className="input-group-append d-flex justify-content-between"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="d-flex px-0">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            className="form-control responsive-input-1"
                            placeholder="Search"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            // value={searchDeviceList}
                            value={searchInputValue}
                            style={{
                              width: "25vw",
                              borderRadius: "5px",
                            }}
                            // onChange={(e) =>
                            //   setSearchDeviceList(e.target.value)
                            // }
                            onChange={handleInputChange}
                          />
                          {searchInputValue && (
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "30%",
                                color: "gray",
                              }}
                              onClick={() => {
                                setSearchDeviceList("");
                                setSearchInputValue("");
                              }}
                            />
                          )}
                        </div>
                        <div className="mx-1 btns">
                          <Button variant="primary" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {pageType !== "activeDevices" && (
                          <Button
                            className="btns"
                            variant="primary"
                            onClick={() => toUnassignedDevices()}
                          >
                            Unused [{unassignedDevices}]
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          className="mx-1 btns"
                          onClick={exportList}
                          disabled={devices.length === 0}
                        >
                          Export
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="px-0">
                    {/* Delete Modal */}
                    <Modal
                      show={isDeleteConfirmationOpen}
                      onHide={closeDeleteConfirmation}
                      className="modal-dialog-centered"
                      animation={false}
                      backdrop="static"
                      autoFocus="true"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to delete this device?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className="btns"
                          variant="secondary"
                          onClick={closeDeleteConfirmation}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btns"
                          variant="danger"
                          onClick={(e) => {
                            confirmDelete(e);
                            closeDeleteConfirmation();
                          }}
                        >
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="employee-table">
                      <div
                        className="table-container"
                        style={{
                          maxHeight: "70vh",
                          overflow: "auto",
                        }}
                      >
                        <table className="table-responsive">
                          <thead className="fixed-header">
                            <tr>
                              <th>Sr. No</th>
                              <th
                                className="text-left"
                                onClick={() => handleSort("deviceName")}
                              >
                                Device Name &nbsp;
                                {sortBy === "deviceName" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th
                                className="text-left"
                                onClick={() => handleSort("operatorName")}
                              >
                                Operator Name &nbsp;
                                {sortBy === "operatorName" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th onClick={() => handleSort("operatorCode")}>
                                Operator Code &nbsp;
                                {sortBy === "operatorCode" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th onClick={() => handleSort("operatorContact")}>
                                Operator Contact &nbsp;
                                {sortBy === "operatorContact" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th
                                className="text-left"
                                onClick={() => handleSort("buMaster.buName")}
                              >
                                Service Station Name &nbsp;
                                {sortBy === "buMaster.buName" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th onClick={() => handleSort("testCount")}>
                                Vehicles Tested &nbsp;
                                {sortBy === "testCount" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th
                                className="text-left"
                                onClick={() => handleSort("createdDate")}
                              >
                                {/* Registration Date &nbsp; */}
                                Created Date &nbsp;
                                {sortBy === "createdDate" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th
                                className="text-left"
                                onClick={() => handleSort("updatedDate")}
                              >
                                {/* Updated Date &nbsp; */}
                                Registration Date &nbsp;
                                {sortBy === "updatedDate" && (
                                  <FontAwesomeIcon
                                    icon={reverse ? faArrowDown : faArrowUp}
                                  />
                                )}{" "}
                              </th>
                              <th className="text-left">Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoadingListData &&
                              devices.length > 0 &&
                              devices.map((device, index) => (
                                <tr
                                  className="clickable-row"
                                  onClick={(e) => {
                                    if (
                                      !e.target.classList.contains("delete-btn")
                                    ) {
                                      handleRowClick(device, e);
                                    }
                                  }}
                                  key={device.id}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  <td>{startingSerialNumber + index}</td>
                                  <td className="text-left">
                                    {device.deviceName}
                                  </td>
                                  <td className="text-left">
                                    {device.operatorName}
                                  </td>
                                  <td>{device.operatorCode}</td>
                                  <td>{device.operatorContact}</td>
                                  <td className="text-left">
                                    {device.buMasterObj.buName}
                                  </td>
                                  <td>{device.vehicleTestObj.vehicleCount}</td>
                                  {/* Format the date using toLocaleDateString */}
                                  <td className="text-left">
                                    {new Date(
                                      device.createdDate
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td className="text-left">
                                    {new Date(
                                      device.updatedDate
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td className="text-left">
                                    {device.deviceStatusMasterName}
                                  </td>
                                  <td onClick={(e) => e.stopPropagation()}>
                                    <button
                                      className="delete-btn btns"
                                      style={{
                                        color: "black",
                                        border: "none",
                                      }}
                                      onClick={() =>
                                        openDeleteConfirmation(device)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={(e) => {
                                          openDeleteConfirmation(e, device);
                                        }}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {!isLoadingListData && devices.length === 0 && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "15px",
                            }}
                          >
                            <img src={Notfound} alt="Not Found" />
                            <p
                              style={{
                                marginTop: "-10px",
                                fontWeight: "bold",
                              }}
                            >
                              No Devices Found
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {!isLoadingListData && devices.length > 0 && (
                      <div className="px-0 d-flex justify-content-end fixed">
                        <div className="pagination-buttons">
                          <div className="pagination-button">
                            <div
                              className={`circle ${
                                currentPage === 1 || devices.length === 0
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => handlePagination("prev")}
                            >
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                          </div>

                          <div className="pagination-info">
                            <p>
                              {/* {rangeStart} - {rangeEnd} of {deviceCount}{" "} */}
                              {devices.length > 0 ? rangeStart : 0} -{" "}
                              {devices.length > 0 ? rangeEnd : 0} of{" "}
                              {devices.length > 0 ? deviceCount : 0}{" "}
                            </p>
                          </div>

                          <div className="pagination-button">
                            <div
                              className={`circle ${
                                currentPage === totalPages ||
                                devices.length === 0
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => handlePagination("next")}
                            >
                              <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DevicesList;
