import React, { useEffect, useState, useRef } from "react";
// import "./../../App.css"; // Importing the CSS file
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import ErrorPage from "../../ErrorPage";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

const AllVehiclesTestedList = () => {
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState([]);
  // const [searchTest, setsearchTest] = useState("");
  const [searchTest, setsearchTest] = useState(() => {
    return sessionStorage.getItem("searchInputValue") || "";
  });
  const [buListDropDown, setBuListDropDown] = useState([]);
  const [makeModelListDropDown, setMakeModelListDropDown] = useState([]);
  // const [selectedServiceStation, setSelectedServiceStation] = useState(null);
  // const [selectedModel, setSelectedModel] = useState(null);
  // const [selectedRegion, setSelectedRegion] = useState(null);
  // const [selectedChannel, setSelectedChannel] = useState(null);
  // const [selectedServiceStationCity, setSelectedServiceStationCity] = useState(null);
  const [vehicleCount, setvehicleCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [dateFilter, setDateFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState(() => {
    return sessionStorage.getItem("dateFilter") || "all";
  });
  // const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const [currentPage, setCurrentPage] = useState(() => {
    return sessionStorage.getItem("currentPage") || 1;
  });
  const itemsPerPage = 100;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + vehicleData.length - 1,
    currentPage * itemsPerPage
  );
  const handleDateFilterChange = (event) => {
    // setDateFilter(event.target.value);
    const selectedDateFilter = event.target.value;
    setDateFilter(selectedDateFilter);
    // setErrorMessage(""); // Reset error message when changing filter
    if (selectedDateFilter === "all") {
      // If 'All' is selected, set startDate and endDate to null
      setStartDate(null);
      setEndDate(null);
      sessionStorage.removeItem("startDate");
      sessionStorage.removeItem("endDate");
    }
  };
  const pageType = "AllVehiclesTestedList";
  const [sortBy, setSortBy] = useState("createdDate");
  const [reverse, setReverse] = useState(false);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  // const [searchInputValue, setSearchInputValue] = useState("");
  const [searchInputValue, setSearchInputValue] = useState(() => {
    return sessionStorage.getItem("searchInputValue") || "";
  });

  const hasToastShownRef = useRef(false);
  const [appVersionListDropDown, setAppVersionListDropDown] = useState([]);
  // const [selectedAppVersion, setSelectedAppVersion] = useState(null);

  const [selectedModel, setSelectedModel] = useState(() => {
    return sessionStorage.getItem("selectedModel") || null;
  });
  const [selectedServiceStation, setSelectedServiceStation] = useState(() => {
    return sessionStorage.getItem("selectedServiceStation") || null;
  });
  const [selectedRegion, setSelectedRegion] = useState(() => {
    return sessionStorage.getItem("selectedRegion") || null;
  });
  const [selectedChannel, setSelectedChannel] = useState(() => {
    return sessionStorage.getItem("selectedChannel") || null;
  });
  const [selectedServiceStationCity, setSelectedServiceStationCity] = useState(
    () => {
      return sessionStorage.getItem("selectedServiceStationCity") || null;
    }
  );
  const [selectedAppVersion, setSelectedAppVersion] = useState(() => {
    return sessionStorage.getItem("selectedAppVersion") || null;
  });
  const [startDate, setStartDate] = useState(() => {
    const storedStartDate = sessionStorage.getItem("startDate");
    return storedStartDate ? new Date(storedStartDate) : null;
  });
  const [endDate, setEndDate] = useState(() => {
    const storedEndDate = sessionStorage.getItem("endDate");
    return storedEndDate ? new Date(storedEndDate) : null;
  });

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning(
              "UserMasterObject values not found in session storage",
              3000
            );
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        return <ErrorPage />;
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchAllVehicleTestData = async () => {
      if (userMasterObject) {
        await fetchVehicleData(currentPage);
        fetchOptions();
      }
    };

    fetchAllVehicleTestData();
  }, [userMasterObject, currentPage, sortBy, reverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1); // Reset current page to 1 when search is cleared
      fetchVehicleData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (
      (startDate !== null && endDate !== null) ||
      selectedServiceStation !== null ||
      selectedModel !== null ||
      selectedRegion !== null ||
      selectedChannel !== null ||
      selectedServiceStationCity !== null ||
      selectedAppVersion !== null
    ) {
      setIsApplyEnabled(true);
    } else {
      setIsApplyEnabled(false);
    }
  }, [
    startDate,
    endDate,
    selectedServiceStation,
    selectedModel,
    selectedRegion,
    selectedChannel,
    selectedServiceStationCity,
    selectedAppVersion,
  ]);

  useEffect(() => {
    if (userMasterObject && startDate === null && endDate === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, startDate, endDate]);

  useEffect(() => {
    if (userMasterObject && selectedServiceStation === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedServiceStation]);

  useEffect(() => {
    if (userMasterObject && selectedRegion === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedRegion]);

  useEffect(() => {
    if (userMasterObject && selectedChannel === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedChannel]);

  useEffect(() => {
    if (userMasterObject && selectedServiceStationCity === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedServiceStationCity]);

  useEffect(() => {
    if (userMasterObject && selectedModel === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedModel]);

  useEffect(() => {
    if (userMasterObject && selectedAppVersion === null) {
      // setCurrentPage(1);
      // fetchVehicleData(1);
      const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      setCurrentPage(storedPage); // Set currentPage to the stored value
      fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
    }
  }, [userMasterObject, selectedAppVersion]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchVehicleData(1);
      //   const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      // setCurrentPage(storedPage); // Set currentPage to the stored value
      // fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (vehicleData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [vehicleData]);

  useEffect(() => {
    if (searchTest && userMasterObject) {
      // console.log("Fetching data due to search button click");
      setCurrentPage(1);
      fetchVehicleData(1); // Fetch data for the first page
      // const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
      // setCurrentPage(storedPage); // Set currentPage to the stored value
      // fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchTest]);

  // Save filters to SessionStorage whenever they change
  useEffect(() => {
    // Check and update SessionStorage for selectedModel
    if (selectedModel) {
      sessionStorage.setItem("selectedModel", selectedModel);
    } else {
      sessionStorage.removeItem("selectedModel"); // Remove if null or "All" is selected
    }

    // Check and update SessionStorage for selectedServiceStation
    if (selectedServiceStation) {
      sessionStorage.setItem("selectedServiceStation", selectedServiceStation);
    } else {
      sessionStorage.removeItem("selectedServiceStation");
    }

    // Check and update SessionStorage for selectedRegion
    if (selectedRegion) {
      sessionStorage.setItem("selectedRegion", selectedRegion);
    } else {
      sessionStorage.removeItem("selectedRegion");
    }

    // Check and update SessionStorage for selectedChannel
    if (selectedChannel) {
      sessionStorage.setItem("selectedChannel", selectedChannel);
    } else {
      sessionStorage.removeItem("selectedChannel");
    }

    // Check and update SessionStorage for selectedServiceStationCity
    if (selectedServiceStationCity) {
      sessionStorage.setItem(
        "selectedServiceStationCity",
        selectedServiceStationCity
      );
    } else {
      sessionStorage.removeItem("selectedServiceStationCity");
    }

    // Check and update SessionStorage for selectedAppVersion
    if (selectedAppVersion) {
      sessionStorage.setItem("selectedAppVersion", selectedAppVersion);
    } else {
      sessionStorage.removeItem("selectedAppVersion");
    }

    // Check and update SessionStorage for startDate
    if (startDate) {
      sessionStorage.setItem("startDate", startDate);
    } else {
      sessionStorage.removeItem("startDate");
    }

    // Check and update SessionStorage for endDate
    if (endDate) {
      sessionStorage.setItem("endDate", endDate);
    } else {
      sessionStorage.removeItem("endDate");
    }

    if (dateFilter) {
      sessionStorage.setItem("dateFilter", dateFilter);
    } else {
      sessionStorage.removeItem("dateFilter");
    }

    if (currentPage) {
      sessionStorage.setItem("currentPage", currentPage);
    } else {
      sessionStorage.removeItem("currentPage");
    }

    if (searchInputValue) {
      sessionStorage.setItem("searchInputValue", searchInputValue);
    } else {
      sessionStorage.removeItem("searchInputValue");
    }
  }, [
    selectedModel,
    selectedServiceStation,
    selectedRegion,
    selectedChannel,
    selectedServiceStationCity,
    selectedAppVersion,
    startDate,
    endDate,
    dateFilter,
    currentPage,
    searchInputValue,
  ]);

  // Allvehiclestested List Api
  const fetchVehicleData = async (page) => {
    // debugger;
    // Set loading state to true
    setIsLoadingListData(true);

    try {
      const response = await fetch(
        serverUrl + "vehicle/allvehiclestest/list",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: userMasterObject.ouMasterId,
            searchText: searchTest || "",
            buMasterId: selectedServiceStation,
            makeModelMasterId: selectedModel,
            BuRegion: selectedRegion,
            BuChannel: selectedChannel,
            BuLocation: selectedServiceStationCity,
            startDate: startDate,
            endDate: endDate,
            count: itemsPerPage,
            pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
            sortBycolumn: sortBy, // Pass the sorting column
            sortByFlag: reverse, // Pass the boolean flag directly
            appVersion: selectedAppVersion,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.vehicleTestList != null &&
            data.entity.vehicleTestList.length > 0 &&
            data.entity.totalCount != null
          ) {
            setVehicleData(data.entity.vehicleTestList);
            setvehicleCount(data.entity.totalCount);
            // Calculate total pages based on totalCount and itemsPerPage
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching vehicle data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  // Tablerow Onclick
  const handleRowClick = (vehicle, e) => {
    try {
      if (vehicle && vehicle.vehicleId && vehicle.ouMasterId) {
        const state = { vehicle, pageType };
        navigate(
          `/wheelsAnalysisReport/${vehicle.vehicleId}/${vehicle.ouMasterId}`,
          { state }
        );
      } else {
        toastWarning("No vehicle tested Records Found", 3000);
        return;
      }
    } catch (error) {
      toastWarning("Please Reload", 3000);
      // console.log(error);
      return <ErrorPage />;
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }

    // Fetch data for the new page
    // await fetchVehicleData(newPage);

    // Update current page after fetching data
    setCurrentPage(newPage);
  };

  // Search In List
  // const handleSearch = () => {
  //   if (!searchTest.trim()) {
  //     toastWarning("Please enter a search keyword.", 3000);
  //     return;
  //   }

  //   setCurrentPage(1); // Reset current page to 1
  //   fetchVehicleData(1);
  // };

  // Get All Records Once For CSV
  const exportList = async () => {
    try {
      const response = await fetch(serverUrl + "allvehiclestest/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          searchText: searchTest || "",
          buMasterId: selectedServiceStation,
          makeModelMasterId: selectedModel,
          BuRegion: selectedRegion,
          BuChannel: selectedChannel,
          BuLocation: selectedServiceStationCity,
          startDate: startDate,
          endDate: endDate,
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
          appVersion: selectedAppVersion,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Custom Date Filter
  const handleApply = async () => {
    // debugger;
    if (dateFilter === "all") {
      // If 'All' is selected, set startDate and endDate to null
      setStartDate(null);
      setEndDate(null);
      sessionStorage.removeItem("startDate");
      sessionStorage.removeItem("endDate");
    }

    setCurrentPage(1); // Reset current page to 1
    fetchVehicleData(1);
    // const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
    // setCurrentPage(storedPage); // Set currentPage to the stored value
    // fetchVehicleData(storedPage); // Fetch vehicle data for the stored page
  };

  // Fetch Options for Drop Down
  const fetchOptions = async () => {
    // debugger;
    try {
      await fetch(serverUrl + "get/all/bu/make_model", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // debugger;

          if (data.entity != null) {
            //if data.entity.buList is empty then show toast that no records found
            //set service stations to drop down
            if (data.entity.buList != null && data.entity.buList.length > 0) {
              // setBuListDropDown((data.entity.buList.map((item) => (item.buCode + " " + item.buName + " "))));

              setBuListDropDown(data.entity.buList);
            } else {
              console.error("No service station found: " + data.entity);
              toastWarning("Service station not found.", 3000);
              return;
            }

            if (
              data.entity.allMakeModelList != null &&
              data.entity.allMakeModelList.length > 0
            ) {
              // setMakeModelListDropDown((data.entity.buList.map((item) => (item.buCode + " " + item.buName + " "))));

              setMakeModelListDropDown(data.entity.allMakeModelList);
            } else {
              console.error("No make models found: " + data.entity);
              toastWarning("Make Models not found.", 3000);
              return;
            }
            if (
              data.entity.allAppVersionList != null &&
              data.entity.allAppVersionList.length > 0
            ) {
              setAppVersionListDropDown(data.entity.allAppVersionList);
            } else {
              console.error("No App Version Found: " + data.entity);
              toastWarning("App Version Not Found.", 3000);
              return;
            }
          } else if (data.errors != null) {
            //check data.errors if not null then show toast data.errors.errorDescription

            let errorDiscription = data.errors.errorDescription;

            toastWarning(errorDiscription, 3000);

            return;
          } else {
            toastWarning("Something Went Wrong", 3000);

            return;
          }
        });
    } catch (err) {}
  };

  const handleSort = (newSortBy) => {
    // debugger;
    setReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSortBy(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
    // const storedPage = parseInt(sessionStorage.getItem("currentPage")) || 1; // Retrieve currentPage from sessionStorage
    // setCurrentPage(storedPage); // Set currentPage to the stored value
  };

  // Function to handle no Vehicles Test found
  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Vehicles Test Found", 3000);
      setVehicleData([]);
    }
    // Reset to initial values if no data is found
    setvehicleCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    debugger;
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      // toastWarning("Please enter a search keyword.", 3000);
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  const saveScrollPosition = () => {
    const container = document.getElementById("scroll");
    if (container) {
      sessionStorage.setItem("scroll-position", container.scrollTop);
    }
  };

  // Attach the event listener when the component mounts
  useEffect(() => {
    const container = document.getElementById("scroll");
    if (container) {
      container.addEventListener("scroll", saveScrollPosition);
    }

    // Clean up the event listener on unmount
    return () => {
      if (container) {
        container.removeEventListener("scroll", saveScrollPosition);
      }
    };
  }, []);

  // Restore scroll position
  const restoreScrollPosition = () => {
    const container = document.getElementById("scroll");
    const scrollPosition = sessionStorage.getItem("scroll-position");
    if (container && scrollPosition) {
      setTimeout(() => {
        container.scrollTop = scrollPosition;
      }, 0); // Give a delay to ensure content is loaded
    }
  };

  useEffect(() => {
    if (!isLoadingListData && vehicleData.length > 0) {
      restoreScrollPosition();
    }
  }, [isLoadingListData, vehicleData]);

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <ToastContainer />
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid px-0 mx-0">
            <div className="row d-flex px-0 mx-0 ">
              <div className="col-lg-12 px-0 mx-0">
                <div className="row px-0 mx-0">
                  <div className="col-md-12 px-0">
                    {/* Header Section Start */}
                    <Header />
                    {/* Header Section End */}
                  </div>
                </div>

                <div
                  className="mx-2 px-0"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                >
                  <div className="row mx-0 mt-2 px-2 pb-3">
                    {/* <div className="my-2 px-0 d-flex justify-content-between">
                      <div className="d-flex">
                        <div style={{ position: "relative" }}> */}
                    <div className="my-2 px-0 d-flex flex-wrap justify-content-between align-items-center">
                      <div className="d-flex flex-wrap align-items-center">
                        <div style={{ position: "relative", flex: "1" }}>
                          <input
                            type="text"
                            className="form-control responsive-input"
                            style={{
                              width: "25vw",
                              borderRadius: "5px",
                            }}
                            placeholder="Search"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            // onChange={(e) => setsearchTest(e.target.value)}
                            // value={searchTest}
                            value={searchInputValue}
                            onChange={handleInputChange}
                          />
                          {searchInputValue && (
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "30%",
                                color: "gray",
                              }}
                              onClick={() => {
                                setsearchTest("");
                                setSearchInputValue("");
                              }}
                            />
                          )}
                        </div>
                        <div className="input-group-append">
                          <Button
                            variant="primary"
                            className="mx-1 btns search-btn"
                            onClick={handleSearch}
                          >
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </Button>
                        </div>
                      </div>

                      {/* Drop Down For Date Picker */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "auto",
                          marginLeft: "24px",
                        }}
                      >
                        <label
                          htmlFor="DateRange"
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          Date Range:
                        </label>
                        <select
                          value={dateFilter}
                          onChange={handleDateFilterChange}
                          style={{
                            padding: "6px",
                            fontSize: "15px",
                            borderRadius: "5px",
                            marginRight: "10px",
                            // marginTop: "4px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <option value="all">All</option>
                          <option value="custom">Custom Date</option>
                        </select>

                        {dateFilter === "custom" && (
                          <>
                            <DatePicker
                              placeholderText="Start Date"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yy"
                              style={{
                                width: "15vw",
                                borderRadius: "5px",
                                marginRight: "10px",
                                fontSize: "14px",
                                padding: "5px",
                                marginTop: "5px",
                              }}
                            />
                            <span style={{ margin: "0 5px" }}>-</span>{" "}
                            {/* Adjust the margin as needed */}
                            <DatePicker
                              placeholderText="End Date"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="dd/MM/yy"
                              style={{
                                width: "15vw",
                                borderRadius: "5px",
                                marginRight: "15px",
                                fontSize: "14px",
                                padding: "5px",
                              }}
                            />
                          </>
                        )}
                      </div>

                      <Button onClick={handleApply} disabled={!isApplyEnabled}>
                        Apply
                      </Button>

                      <Button
                        variant="primary"
                        className="mx-1 btns"
                        onClick={exportList}
                        disabled={vehicleData.length === 0}
                      >
                        Export
                      </Button>
                    </div>

                    <div
                      className="col-md-12 mx-0 px-0"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        className="AllVehiclesDropdownOptions"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "10px",
                        }}
                      >
                        {/* Dropdown for Service Station */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label
                            htmlFor="ServiceStation"
                            style={{
                              marginRight: "5px",
                              fontWeight: "bold",
                              marginLeft: "5px",
                            }}
                          >
                            Service Station:
                          </label>
                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedServiceStation
                                ? (() => {
                                    const selectedOption = buListDropDown.find(
                                      (option) =>
                                        option.buMasterId ===
                                        selectedServiceStation
                                    );
                                    return {
                                      value: selectedOption?.buMasterId,
                                      label: selectedOption
                                        ? `${selectedOption.buCode} ${selectedOption.buName}`
                                        : "All",
                                    };
                                  })()
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "250px",

                                // Set your desired width for the control (input area)
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "250px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...buListDropDown.map((option) => ({
                                value: option.buMasterId,
                                label: option.buCode + " " + option.buName,
                              })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedServiceStation(null);
                              } else {
                                setSelectedServiceStation(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        {/* Dropdown for Region */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <label
                            htmlFor="Region"
                            style={{ marginRight: "10px", fontWeight: "bold" }}
                          >
                            Region:
                          </label>

                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedRegion
                                ? {
                                    value: selectedRegion,
                                    label: selectedRegion,
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                // maxwidth: "200px", // Set your desired width for the control (input area)
                                width: "100px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "100px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...[
                                ...new Set(
                                  buListDropDown.map(
                                    (option) => option.buRegion
                                  )
                                ),
                              ]
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((region) => ({
                                  value: region,
                                  label: region,
                                })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedRegion(null);
                              } else {
                                setSelectedRegion(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        {/* Dropdown for Channel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <label
                            htmlFor="Channel"
                            style={{ marginRight: "10px", fontWeight: "bold" }}
                          >
                            Channel:
                          </label>

                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedChannel
                                ? {
                                    value: selectedChannel,
                                    label: selectedChannel,
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                // maxwidth: "200px", // Set your desired width for the control (input area)
                                width: "140px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "140px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...[
                                ...new Set(
                                  buListDropDown.map(
                                    (option) => option.buChannel
                                  )
                                ),
                              ]
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((channel) => ({
                                  value: channel,
                                  label: channel,
                                })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedChannel(null);
                              } else {
                                setSelectedChannel(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        {/* Dropdown for City */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <label
                            htmlFor="City"
                            style={{ marginRight: "10px", fontWeight: "bold" }}
                          >
                            City:
                          </label>

                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedServiceStationCity
                                ? {
                                    value: selectedServiceStationCity,
                                    label: selectedServiceStationCity,
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                // maxwidth: "200px", // Set your desired width for the control (input area)
                                width: "150px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "150px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...[
                                ...new Set(
                                  buListDropDown.map(
                                    (option) => option.buLocation
                                  )
                                ),
                              ]
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((city) => ({
                                  value: city,
                                  label: city,
                                })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedServiceStationCity(null);
                              } else {
                                setSelectedServiceStationCity(
                                  selectedOption.value
                                );
                              }
                            }}
                          />
                        </div>

                        {/* Dropdown for Model */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <label
                            htmlFor="Model"
                            style={{ marginRight: "10px", fontWeight: "bold" }}
                          >
                            Model:
                          </label>

                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedModel
                                ? {
                                    value: selectedModel,
                                    label: makeModelListDropDown.find(
                                      (option) => option.id === selectedModel
                                    )?.modelName,
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                // maxwidth: "200px", // Set your desired width for the control (input area)
                                width: "150px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "150px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...makeModelListDropDown.map((option) => ({
                                value: option.id,
                                label: option.modelName,
                              })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedModel(null);
                              } else {
                                setSelectedModel(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        {/* Dropdown for App Version */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <label
                            htmlFor="AppVersion"
                            style={{ marginRight: "10px", fontWeight: "bold" }}
                          >
                            App Version:
                          </label>

                          <Select
                            // defaultValue={{ value: null, label: "All" }} // Set default value to the "All" option
                            value={
                              selectedAppVersion
                                ? {
                                    value: selectedAppVersion,
                                    label: selectedAppVersion,
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "100px", // Set your desired width for the control (input area)
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "100px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option here
                              ...[
                                ...new Set(
                                  appVersionListDropDown.map(
                                    (option) => option.appVersion
                                  )
                                ),
                              ]
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((version) => ({
                                  value: version,
                                  label: version,
                                })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              // Handle the selected option here
                              if (selectedOption.value === null) {
                                // If "All" is selected, set the value to null
                                setSelectedAppVersion(null);
                              } else {
                                setSelectedAppVersion(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        {/* <Button
                          onClick={handleApply}
                          disabled={!isApplyEnabled}
                          style={{
                            height: "40px",
                            fontSize: "14px",
                            marginLeft: "30px",
                            width: "73px",
                          }}
                        >
                          Apply
                        </Button> */}
                      </div>
                    </div>

                    <div className="col-md-12 mx-0 px-0">
                      <div className="card-container test-details">
                        <div>
                          <div
                            id="scroll"
                            className="table-container"
                            style={{ maxHeight: "65vh", overflow: "auto" }}
                          >
                            <table>
                              <thead className="fixed-header">
                                <tr>
                                  <th>Sr. No</th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.buMaster.buRegion")
                                    }
                                  >
                                    Region &nbsp;
                                    {sortBy === "device.buMaster.buRegion" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}{" "}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.buMaster.buChannel")
                                    }
                                  >
                                    {" "}
                                    Channel &nbsp;
                                    {sortBy === "device.buMaster.buChannel" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.buMaster.buCode")
                                    }
                                  >
                                    {" "}
                                    Service Station Code &nbsp;
                                    {sortBy === "device.buMaster.buCode" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.buMaster.buName")
                                    }
                                  >
                                    {" "}
                                    Service Station Name &nbsp;
                                    {sortBy === "device.buMaster.buName" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.buMaster.buLocation")
                                    }
                                  >
                                    {" "}
                                    Service Station City &nbsp;
                                    {sortBy ===
                                      "device.buMaster.buLocation" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("device.operatorName")
                                    }
                                  >
                                    {" "}
                                    SA Name (User ID) &nbsp;
                                    {sortBy === "device.operatorName" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}{" "}
                                  </th>

                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("vehicleRegistrationNumber")
                                    }
                                  >
                                    Registration Number &nbsp;
                                    {sortBy === "vehicleRegistrationNumber" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>

                                  <th className="text-left">Make(Model)</th>
                                  <th onClick={() => handleSort("totalKm")}>
                                    Total KM &nbsp;
                                    {sortBy === "totalKm" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th onClick={() => handleSort("vehicleYear")}>
                                    Vehicle Year &nbsp;
                                    {sortBy === "vehicleYear" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("createdDate")}
                                  >
                                    Created Date &nbsp;
                                    {sortBy === "createdDate" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("calculatedFrontLeft")
                                    }
                                  >
                                    App Prediction
                                    <br />
                                    (Front-Left) &nbsp;
                                    {sortBy === "calculatedFrontLeft" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("calculatedFrontRight")
                                    }
                                  >
                                    App Prediction
                                    <br />
                                    (Front-Right) &nbsp;
                                    {sortBy === "calculatedFrontRight" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("calculatedBackLeft")
                                    }
                                  >
                                    App Prediction
                                    <br />
                                    (Back-Left) &nbsp;
                                    {sortBy === "calculatedBackLeft" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("calculatedBackRight")
                                    }
                                  >
                                    App Prediction
                                    <br />
                                    (Back-Right) &nbsp;
                                    {sortBy === "calculatedBackRight" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("userFrontLeft")}
                                  >
                                    Manual Prediction
                                    <br />
                                    (Front-Left) &nbsp;
                                    {sortBy === "userFrontLeft" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("userFrontRight")}
                                  >
                                    Manual Prediction
                                    <br />
                                    (Front-Right) &nbsp;
                                    {sortBy === "userFrontRight" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("userBackLeft")}
                                  >
                                    Manual Prediction
                                    <br />
                                    (Back-Left) &nbsp;
                                    {sortBy === "userBackLeft" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("userBackRight")}
                                  >
                                    Manual Prediction
                                    <br />
                                    (Back-Right) &nbsp;
                                    {sortBy === "userBackRight" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("isFrontLeftEqual")
                                    }
                                  >
                                    Prediction Matching
                                    <br />
                                    (Front-Left) &nbsp;
                                    {sortBy === "isFrontLeftEqual" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("isFrontRightEqual")
                                    }
                                  >
                                    Prediction Matching
                                    <br />
                                    (Front-Right) &nbsp;
                                    {sortBy === "isFrontRightEqual" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("isBackLeftEqual")
                                    }
                                  >
                                    Prediction Matching
                                    <br />
                                    (Back-Left) &nbsp;
                                    {sortBy === "isBackLeftEqual" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("isBackRightEqual")
                                    }
                                  >
                                    Prediction Matching
                                    <br />
                                    (Back-Right) &nbsp;
                                    {sortBy === "isBackRightEqual" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      handleSort("overallYesPercentage")
                                    }
                                  >
                                    Percentage <br /> Matched &nbsp;
                                    {sortBy === "overallYesPercentage" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!isLoadingListData &&
                                  vehicleData.length > 0 &&
                                  vehicleData.map((vehicle, index) => (
                                    <tr
                                      className="clickable-row"
                                      onClick={(e) => {
                                        handleRowClick(
                                          {
                                            vehicleId: vehicle.id,
                                            ouMasterId:
                                              userMasterObject.ouMasterId,
                                            MakeName:
                                              vehicle.makeModelMasterObj
                                                .makeName,
                                            ModelName:
                                              vehicle.makeModelMasterObj
                                                .modelName,
                                            RegistrationNumber:
                                              vehicle.vehicleRegistrationNumber,
                                            TotalKm: vehicle.totalKm,
                                            VehicleYear: vehicle.vehicleYear,
                                            CreatedDate: `${new Date(
                                              vehicle.createdDate
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })} ${new Date(
                                              vehicle.createdDate
                                            ).toLocaleTimeString("en-US", {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            })}`,
                                          },
                                          e
                                        );
                                      }}
                                      key={vehicle.id}
                                      style={{
                                        whiteSpace: "nowrap",
                                        borderBottom: "1px solid #ddd",
                                      }}
                                    >
                                      <td>{startingSerialNumber + index}</td>
                                      <td className="text-left">
                                        {vehicle.buMasterObj.buRegion}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.buMasterObj.buChannel}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.buMasterObj.buCode}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.buMasterObj.buName}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.buMasterObj.buLocation}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.deviceMasterObj.operatorName} (
                                        {vehicle.deviceMasterObj.operatorCode})
                                      </td>
                                      <td className="text-left">
                                        {vehicle.vehicleRegistrationNumber}
                                      </td>
                                      <td className="text-left">{`${vehicle.makeModelMasterObj.makeName} (${vehicle.makeModelMasterObj.modelName})`}</td>
                                      <td>{vehicle.totalKm}</td>
                                      <td>{vehicle.vehicleYear}</td>
                                      {/* Format the date using toLocaleDateString */}
                                      <td className="text-left">
                                        {new Date(
                                          vehicle.createdDate
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })}{" "}
                                        {new Date(
                                          vehicle.createdDate
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.calculatedFrontLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.calculatedFrontRight}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.calculatedBackLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.calculatedBackRight}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.userFrontLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.userFrontRight}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.userBackLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.userBackRight}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.predictionMatchingFrontLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.predictionMatchingFrontRight}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.predictionMatchingBackLeft}
                                      </td>
                                      <td className="text-left">
                                        {vehicle.predictionMatchingBackRight}
                                      </td>
                                      <td>
                                        {vehicle.overallPercentageMatching}%
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {!isLoadingListData && vehicleData.length === 0 && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "15px",
                                }}
                              >
                                <img src={Notfound} alt="Not Found" />
                                <p
                                  style={{
                                    marginTop: "-10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  No Vehicles Test Found
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        {!isLoadingListData && vehicleData.length > 0 && (
                          <div className="col-md-12 mt-3 d-flex justify-content-end">
                            <div className="pagination-buttons">
                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === 1 ||
                                    vehicleData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("prev")}
                                >
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                              </div>

                              <div className="pagination-info">
                                <p>
                                  {/* {rangeStart} - {rangeEnd} of {vehicleCount}{" "} */}
                                  {vehicleData.length > 0 ? rangeStart : 0} -{" "}
                                  {vehicleData.length > 0 ? rangeEnd : 0} of{" "}
                                  {vehicleData.length > 0 ? vehicleCount : 0}{" "}
                                </p>
                              </div>

                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === totalPages ||
                                    vehicleData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("next")}
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllVehiclesTestedList;
